<!-- 智慧助理 -->
<template>
  <div>
    <!-- 浮動按鈕 -->
    <v-btn class="floating-btn" style="background-color: #0288d1" dark fab @click="showDialog">
      <v-icon>mdi-chat-question</v-icon>
    </v-btn>

    <!-- 聊天窗口 -->
    <v-dialog v-if="dialog" v-model="chatDialog" width="400" content-class="chat-dialog rounded-xl">
      <v-card>
        <v-card-title class="cyan darken-1 white--text">
          <v-img v-if="dialogIcon" :src="dialogIcon" max-width="40" class="mr-2" />
          <v-img v-else src="@/assets/favicon_white.png" max-width="40" class="mr-2" />
          {{ dialogTitle }}
        </v-card-title>
        <v-progress-linear :indeterminate="loading" color="cyan"></v-progress-linear>
        <!-- 聊天內容 -->
        <v-container class="chat-container" ref="chatContainer">
          <v-list>
            <div v-for="(item, index) in msgData" :key="index">
              <!-- 通知訊息 -->
              <v-list-item v-if="item.divider">
                <v-divider />
                <span class="ma-2" style="font-size: 13px; color: #bdbdbd">{{ item.msg }}</span>
                <v-divider />
              </v-list-item>
              <!-- 對話訊息 -->
              <v-list-item v-else>
                <!-- 機器人頭像 -->
                <v-list-item-icon v-if="item.bot">
                  <v-avatar color="cyan darken-1" size="40">
                    <v-img v-if="item.icon" :src="item.icon" />
                    <v-img v-else contain src="@/assets/favicon_white.png" />
                  </v-avatar>
                </v-list-item-icon>
                <!-- 訊息內容 -->
                <v-list-item-content>
                  <v-row>
                    <v-col :class="{ 'text-right': !item.bot }">
                      <v-list-item-subtitle
                        style="color: black; font-size: 16px"
                        class="mt-1 pa-3 text-left chat-message rounded-lg"
                        :style="{ backgroundColor: item.bot ? 'aquamarine' : '#E0F7FA' }"
                      >
                        {{ item.msg }}
                      </v-list-item-subtitle>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <!-- 用戶頭像 -->
                <!-- <v-list-item-icon v-if="!item.bot">
                  <v-avatar color="light-blue lighten-3" size="40">
                    <v-icon dark> mdi-account </v-icon>
                  </v-avatar>
                </v-list-item-icon> -->
              </v-list-item>
            </div>
          </v-list>
        </v-container>
        <!-- 預設問題選擇 -->
        <v-chip-group>
          <v-chip v-for="(str, index) in questionList" :key="index" :disabled="loading" outlined @click="sendMsg(str)">
            {{ str }}
          </v-chip>
        </v-chip-group>
        <!-- 輸入框 -->
        <v-divider />
        <v-container>
          <v-row no-gutters>
            <v-col>
              <v-textarea
                :disabled="loading"
                ref="chatEdit"
                class="chat-edit no-underline"
                v-model="editValue"
                label="輸入訊息"
                auto-grow
                rows="1"
                @keydown.enter.prevent="onSendMsg"
              ></v-textarea>
            </v-col>
            <v-col cols="1" class="pt-2">
              <!-- 發送紐 -->
              <v-btn v-if="editValue" :disabled="loading" icon @click="onSendMsg">
                <v-icon color="light-blue darken-1">mdi-send</v-icon>
              </v-btn>
              <!-- 麥克風 -->
              <v-btn v-else icon :disabled="loading" @click="onMic">
                <v-icon>{{ isVoice ? "mdi-close" : "mdi-microphone-outline" }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- 語音視窗 -->
    <voice-dialog :show.sync="isVoice" @on-blob="onVoiceBlob" />
  </div>
</template>

<script>
import VoiceDialog from "@/components/VoiceDialog.vue";
import VoiceBotServer from "@/lib/voice-bot-server";

/*
      
  msgData 資料格式
  {
    msg     : 必 | str  | 訊息
    divider : 選 | bool | 是否為系統訊息
    bot     : 選 | bool | 是否為機器人
    action  : 選 | fun  | 要執行的動作
  }
      
*/

export default {
  components: { VoiceDialog },
  data() {
    return {
      chatDialog: false,
      dialog: false, // 用於重整 DOM (防切頁面時顯示異常)
      dialogTitle: "樂樂社區管家",
      dialogIcon: "",
      msgData: [],
      editValue: "",
      loading: false,
      questionList: ["九月份舉辦了幾次下午茶會", "今天台北的天氣好嗎", "社區遭小偷的處理流程", "我想找鎖匠"], // 預設問題
      isVoice: false, // 是否啟用語音
      voiceBot: new VoiceBotServer(),
    };
  },
  methods: {
    /** 開場白 */
    opening() {
      if (this.msgData.length) return;
      this.setBotMsg("你好! 我是樂樂管家,\n請問有需要什麼幫助嗎?");
    },

    /** 顯示視窗 */
    showDialog() {
      this.chatDialog = !this.chatDialog;
      this.opening();
    },

    /**
     * 發送消息
     * @param {string} msg 訊息內容
     */
    sendMsg(msg) {
      this.msgData.push({ msg });
      this.sendToBot(msg);
    },

    /**
     * [事件] 監聽輸入框 Enter 事件
     * @param {MouseEvent} e 點擊事件
     */
    onSendMsg(e) {
      if (this.loading || !this.editValue) return;
      // 當 Shift + Enter 時換行
      if (e.shiftKey) {
        this.editValue += "\n";
      } else {
        this.sendMsg(this.editValue);
        this.editValue = null;
      }
    },

    /**
     * [事件] 使用麥克風輸入
     */
    onMic() {
      this.isVoice = !this.isVoice;
      this.isShowVoice = !this.isShowVoice;
    },

    /**
     * [事件] 監聽語音輸入
     * @param {Blob} blob 聲音數據
     */
    async onVoiceBlob(blob) {
      if (blob) {
        this.loading = true;
        let msg = await this.voiceBot.speechToText(blob).finally(() => (this.loading = false));
        this.editValue = msg;
      }
    },

    /**
     * 發訊息給機器人
     * @param {string} msg 訊息內容
     */
    async sendToBot(msg) {
      if (this.loading || !msg || this.sendToBot_test(msg)) return;
      this.loading = true;
      let res = await this.$api.sendMsgToBot(msg).finally(() => (this.loading = false));
      this.msgData.push({ msg: res.output, bot: true });
    },

    /**
     * 模擬機器人訊息
     * @param  {...any} msgData 訊息(可多個)
     */
    setBotMsg(...msgData) {
      this.loading = true;
      if (this.intervalId) return;
      this.intervalId = setInterval(() => {
        if (msgData.length) {
          let data = msgData.shift();
          if (typeof data == "string") data = { msg: data };
          data.bot = true;
          this.msgData.push(data);
          data.action?.call();
          // 結束訊息發送
          if (!msgData.length) {
            clearInterval(this.intervalId);
            this.intervalId = null;
            this.loading = false;
          }
        }
      }, 1300);
    },

    // ===== 測試用方法 =====

    /**
     *  測試用機器人
     * @param {string} msg 訊息內容
     * @returns {bool} 觸發狀態
     */
    sendToBot_test(msg) {
      return this.bot_Bangbu(msg) || this.bot_Nicole(msg);
    },

    /**
     * 邦布(機器人)
     * @param {string} msg 訊息內容
     * @returns {bool | undefined} 觸發狀態
     */
    bot_Bangbu(msg) {
      if (!msg?.includes("嗯呢")) return;

      // 角色頭像
      let iconUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq7s4NZiQF-Guu_eLsUkffUzSGilgHPdVOIA&s";

      // 表示啟用角色
      if (!this.bangbu) {
        this.bangbu = true;
        this.msgData.push({ msg: "好友[伊埃斯]已進入聊天室", divider: true });
      }

      // 邦布對話資料(k:關鍵字, v:回應訊息)
      let chatData = [
        { k: "你好", v: "嗯呢!\n(你好呀!)" },
        { k: "你是誰", v: "嗯呢,嗯呢嗯呢\n(你好呀,我叫伊艾斯)" },
        { k: "伊埃斯", v: "嗯呢!\n(我在!)" },
        { k: "玩", v: "嗯呢嗯呢!\n(一起玩! 一起玩!)" },
      ];

      this.setBotMsg({
        icon: iconUrl,
        msg: chatData.find((e) => msg.includes(e.k))?.v || "嗯呢嗯呢?\n(你是在叫我嗎?)",
      });

      return true;
    },

    /**
     * 妮可(機器人)
     * @param {string} msg 訊息內容
     * @returns {bool | undefined} 觸發狀態
     */
    bot_Nicole(msg) {
      if (!(this.nicole || msg?.includes("妮可"))) return;

      // 表示啟用角色
      if (!this.nicole) {
        this.nicole = true;
        this.msgData.push({ msg: "好友[妮可]已進入聊天室", divider: true });
      }

      // 角色頭像
      let iconUrl = "https://patchwiki.biligame.com/images/zzz/a/ae/2ve5rqnangkt1i8935q3znnh2dakzh3.png";
      // 對話資料(k:關鍵字, v:回應訊息)
      let chatData = [{ k: "粉絲", v: "喵喵喵!?\n你突然間在說甚麼呢!\n(臉紅)" }];
      let chat = chatData.find((e) => msg?.includes(e.k));

      if (chat) {
        this.setBotMsg({ msg: chat.v, icon: iconUrl });
      } else if (msg?.includes("沒事")) {
        this.setBotMsg(
          ...[
            { msg: "既然沒事, 那我就先走了", icon: iconUrl },
            {
              msg: "好友[妮可]已離開聊天室",
              divider: true,
              action: () => {
                this.nicole = false;
                this.setDialogTitle(this.dialogTitle_old);
              },
            },
          ],
        );
      } else {
        this.dialogTitle_old = this.dialogTitle;
        this.setBotMsg(
          ...[
            { msg: "哈, 有事?", icon: iconUrl },
            {
              msg: "好友[妮可]已修改聊天室名稱",
              divider: true,
              action: () => this.setDialogTitle("萬能事務所-狡兔屋✨", "https://patchwiki.biligame.com/images/zzz/b/bc/pgjegfdy49nom444tzodb36vbg957lb.png"),
            },
            { msg: "先說好, 我可是很貴的", icon: iconUrl },
          ],
        );
      }
      return true;
    },

    /**
     * 修改聊天室標題
     * @param {string} title 標題名稱
     * @param {string | null} iconUrl 標題圖示
     */
    setDialogTitle(title, iconUrl) {
      this.dialogTitle = "";
      this.dialogIcon = iconUrl;
      this.botActionId = setInterval(() => {
        if (this.dialogTitle.length != title.length) {
          this.dialogTitle += title[this.dialogTitle.length];
        } else {
          clearInterval(this.botActionId);
        }
      }, 100);
    },
  },
  watch: {
    msgData() {
      // 更新滾動位置，使其置底
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    editValue() {
      // 更新滾動位置，使其置底
      this.$nextTick(() => {
        const input = this.$refs.chatEdit.$refs.input;
        input.scrollTop = input.scrollHeight;
      });
    },
    chatDialog() {
      // 用於重整 Dialog DOM
      if (this.chatDialog) {
        this.dialog = true;
      } else {
        setTimeout(() => (this.dialog = false), 100);
      }
    },
  },
};
</script>

<style>
.floating-btn {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
}

.chat-dialog {
  position: fixed !important;
  bottom: 80px;
  right: 16px;
  margin: 0;
  max-height: calc(100% - 80px);
  overflow: hidden;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.chat-container {
  max-height: 300px;
  min-height: 100px;
  overflow-y: auto; /* 啟用滾動 */
  scrollbar-width: none; /* 隱藏滾動條 - Firefox */
  -ms-overflow-style: none; /* 隱藏滾動條 - IE 10+ */
}

.chat-message {
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-word;
  display: inline-block;
}

.chat-edit {
  max-height: 200px;
  overflow-y: auto; /* 啟用滾動 */
  scrollbar-width: none; /* 隱藏滾動條 - Firefox */
  -ms-overflow-style: none; /* 隱藏滾動條 - IE 10+ */
}

.no-underline .v-input__control .v-input__slot:before,
.no-underline .v-input__control .v-input__slot:after {
  border: none !important;
}
</style>
