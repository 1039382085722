import axios from "axios";
import store from "@/store";

/** 語音機器人 */
class VoiceBotServer {
  // 語音轉文字服務網址
  // SPEECH_TO_TEXT_SERVER_URL = "wss://ctnservice.oneai.twcc.ai:30088/ws?language=zh";
  SPEECH_TO_TEXT_SERVER_URL = "wss://voicebot.ez-ai.com.tw:5002/ws?stt=google&lang=zh-TW";
  // 大腦服務網址
  VOICE_BOT_SERVER_URL = "https://tomoko.aiinfo.cc/chatbot/lele-system-d1f405e1-98fe-45dd-baee-f0648aba346d";

  /** 發送語音內容
   * @param {Blob} data 語音資料
   */
  async send(data) {
    let msg = await this.speechToText(data);
    return this.sendVoiceBot(msg);
  }

  /** 語音轉文字
   * @param {Blob} data 語音資料
   * @returns {Promise<string>} 語音文字
   */
  async speechToText(data) {
    let dataBytes = null;
    if (data instanceof Blob) {
      dataBytes = await data.arrayBuffer();
    }

    if (!dataBytes) return Promise.reject("語音解析失敗");

    return new Promise((resolve, reject) => {
      const socket = new WebSocket(this.SPEECH_TO_TEXT_SERVER_URL);

      // 監聽-開啟後執行的動作
      socket.onopen = () => {
        socket.send(dataBytes);
        socket.send("EOS");
      };

      // 監聽-接收 Server 發送的訊息
      socket.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if (data.code == 1000) {
          socket.close();
          resolve(data.msg);
        } else if (data.code && data.code != 1000) {
          reject(data);
        }
      };

      // 監聽-異常錯誤的事件
      socket.onerror = (err) => reject(err);
    });
  }

  /** 發送訊息給機器人獲取回答
   * @param {string} msg 訊息內容
   * @param {string} token 憑證
   * @returns {Promise} 回應的內容
   */
  async sendVoiceBot(msg) {
    const data = {
      user_says: msg,
      user_id: store.getters.getUser.id,
      answers_reply: 1,
      is_robot: 0,
      external_info: {
        youtube_url: 0,
        no_answer_mode: "random",
        translate_mode: false,
      },
      enable_domain: ["lele_system"],
    };
    let res = await axios.post(this.VOICE_BOT_SERVER_URL, data);
    if (res && res.status == 200) {
      return res.data;
    } else {
      return Promise.reject(res);
    }
  }
}

export default VoiceBotServer;
